const styleDict = { success: 'alert-success', error: 'alert-danger', alert: 'alert-warning' };
let flashCont;
const req = $.ajax({
  type: 'GET',
  url: '/flash_msgs/content',
  dataType: 'json',
  success(msgs) {
    let ref = document.getElementById('flash-msgs');
    if (ref == null) {
      ref = document.body.firstChild;
      flashCont = document.createElement('div');
      flashCont.id = 'flash-msgs';
      document.body.insertBefore(flashCont, ref);
    } else {
      flashCont = ref;
      ref.style.marginBottom = '15px';
    }
    for (let i in msgs) {
      const closeBtn = document.createElement('button');
      closeBtn.className = 'close';
      closeBtn.setAttribute('data-dismiss', 'alert');
      closeBtn.innerHTML = 'x';
      const type = msgs[i][0];
      const msg = msgs[i][1];
      const newMsg = document.createElement('div');
      newMsg.className = `alert ${getClass(type)} alert-dismissible text-center`;
      for (i in msg) {
        const x = $.parseHTML(msg[i]);
        for (const j in x) {
          newMsg.appendChild(x[j]);
        }
      }
      newMsg.appendChild(closeBtn);
      $(flashCont).append(newMsg).fadeIn(500);
    }
    $(flashCont).delay(5000).fadeOut(500);
  },
});

function getClass(key) {
  const value = styleDict[key];
  if (value == null) {
    return 'alert-info';
  }

  return value;
}
